import React, { ReactNode } from 'react'

import GlobalStyle from '../../styles/global'

import Footer from '@interco/inter-frontend-lib-footer/lib/site'
import HeaderSim from '../Header'
import HeaderGoTech from '../HeaderGoTech'

import SEO from '../Seo'

interface ILayout {
  pageContext: {
    metaTitle: string;
    metaDescription: string;
  };
  children: ReactNode;
  isHome?: boolean;
  isGoTech?: boolean;
}

const Layout: React.FC<ILayout> = ({ children, pageContext, isHome, isGoTech }: ILayout) => {
  return (
    <>
      <GlobalStyle />
      <SEO title={pageContext.metaTitle} description={pageContext.metaDescription} />
      {isGoTech ? <HeaderGoTech /> : <HeaderSim isHome={isHome} />}
      <main>{children}</main>
      <Footer />
    </>
  )
}

export default Layout
